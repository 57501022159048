<template>
  <view-item-setting>
    <template #operation>
      <en-button type="primary" @click="operation.add.click">添加班组设置</en-button>
    </template>

    <flex-box>
      <template #default="{ height }">
        <table-dynamic
          :height="height"
          code="WKTMFD"
          :data="table.data"
          :loading="table.loading"
          :paging="table.paging"
          :method="table.get"
          pagination
          :config="table.config"
        >
          <template #OPERATION="{ row }: { row: EnocloudCommonDefinitions['AdvisorTeamDto'] }">
            <button-delete :method="table.operation.delete.click" :params="row">删除</button-delete>
          </template>
          <template #TEAM_NAME="{ row }: { row: EnocloudCommonDefinitions['AdvisorTeamDto'] }">
            <en-button type="primary" link @click="table.operation.name.click(row)">{{ row.name }}</en-button>
          </template>
          <template #MEMBERS="{ row }: { row: EnocloudCommonDefinitions['AdvisorTeamDto'] }">
            {{ row.members.map((i) => i.user.name).join(',') }}
          </template></table-dynamic
        >
      </template>
    </flex-box>
  </view-item-setting>

  <en-drawer v-model="detail.visible" :title="detail.form.data.id ? '编辑班组设置' : '添加班组设置'" size="50%">
    <en-form
      :model="detail.form.data"
      :rules="detail.form.rules"
      :loading="detail.form.loading"
      :ref="setRef('detailForm')"
      class="grid grid-cols-2 gap-2"
    >
      <en-form-item label="班组名称" prop="name">
        <en-input v-model="detail.form.data.name"></en-input>
      </en-form-item>
      <en-form-item label="属性">
        <select-maintain
          v-model="detail.form.data.status"
          :ajax="{
            action: 'GET /enocloud/common/lookup/:lookupType',
            params: (params) => (params.paths = ['WKGTMSTAT'])
          }"
          :props="{ label: 'message', value: '' }"
          value-key="code"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="提成方式">
        <select-maintain
          v-model="detail.form.data.bonusType"
          :ajax="{
            action: 'GET /enocloud/common/lookup/:lookupType',
            params: (params) => (params.paths = ['WKTMBSTYPE'])
          }"
          :props="{ label: 'message', value: '' }"
          value-key="code"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="提成比例" prop="bonusValue">
        <en-input v-model="detail.form.data.bonusValue"></en-input>
      </en-form-item>
      <en-form-item label="备注" prop="comment">
        <en-input v-model="detail.form.data.comment" maxlength="200" type="textarea" show-word-limit></en-input>
      </en-form-item>
    </en-form>

    <div style="color: red; font-size: 14px">
      <p style="color: red; font-size: 14px; margin-top: 0; text-indent: 2em; background: #fff; padding-bottom: 5px">
        说明:班组权重用于统计工时业绩计算规则： 当同一个服务项目有多个技师参与时，工时提成会在这些技师之间按比例分配。分配比例等于技师等级权重比例。
        如果服务项目派工时只派工到班组，工时提成就默认按班组内所有员工进行权重比例计算。
      </p>
      <p
        style="
          color: red;
          font-size: 14px;
          margin-top: 0;

          text-indent: 2em;
          background: #fff;
          padding-bottom: 5px;
          display: flex;
          flex-wrap: nowrap;
        "
      >
         举例：假设门店有三位技师参与某个项目的分成，A技师权重是50；B技师权重是20；C技师权重是10。
        则ABC的总权重是80，A所占业绩比例是50/80=5/8，B所占比业绩例是20/80=1/4，C所占业绩比例是10/80=1/8。该项目的提成金额是100元，则A技师提成：100*5/8=62.5；B技师的提成：
        100*1/8=12.5。
      </p>
    </div>
    <div @click="detail.form.inspectionPlanItems.add.click" class="cursor-pointer text-sm text-primary" style="margin-top: 20px; margin-bottom: 10px">
      添加班组成员
    </div>

    <flex-box>
      <template #default="{ height }">
        <en-table :data="detail.form.data.members" :height="height" :loading="detail.form.loading">
          <en-table-column label="序号" type="index" width="100"></en-table-column>
          <en-table-column label="班组成员">
            <template #default="{ row }: { row: EnocloudCommonDefinitions['WorkingTeamMemberDto'] }">
              <select-maintain
                v-model="row.user"
                :ajax="{
                  action: 'GET /enocloud/admin/user',
                  params: (params, value) => (params.payload = { name: value, statusCode: 'A' })
                }"
                :props="{ label: 'name', value: '' }"
                value-key="id"
                remote
              ></select-maintain>
            </template>
          </en-table-column>

          <en-table-column label="派工权重">
            <template #default="{ row }: { row: EnocloudCommonDefinitions['WorkingTeamDto'] }">
              <en-input-number v-model="row.assignmentWeight" :min="0" :step="1"></en-input-number>
            </template>
          </en-table-column>
          <en-table-column label="操作" width="200">
            <template #default="{ row }: { row: EnocloudCommonDefinitions['WorkingTeamDto'] }">
              <en-button @click="detail.form.inspectionPlanItems.deletes.click" class="cursor-pointer text-sm text-primary">删除</en-button>
            </template>
          </en-table-column>
        </en-table>
      </template>
    </flex-box>

    <template #footer>
      <en-button @click="detail.footer.cancel.click">取消</en-button>
      <button-ajax :method="detail.footer.confirm.click"> 确定 </button-ajax>
    </template>
  </en-drawer>
</template>

<script lang="ts">
export default factory({
  config: {
    children: {
      operation: {
        add: {
          click() {
            this.detail.form.init()
            this.detail.visible = true
          }
        }
      },
      tabs: {
        active: ''
      },
      table: {
        config: {
          BRANCH_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'branchIds',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/branch',
                    params: (params, value) => (params.payload = { quickSearch: value })
                  },
                  remote: true,
                  props: { label: 'shortName', value: 'id' }
                }
              }
            }
          }
        },
        data: [] as InspectionPlanItems[],
        ajax: {
          get: {
            action: 'GET /enocloud/common/workingteam',
            data: 'array',
            loading: true,
            pagination: true
          },
          delete: {
            action: 'DELETE /enocloud/common/workingteam/:workingTeamId',
            loading: true
          }
        },

        children: {
          operation: {
            data: [] as InspectionPlanItems[],
            delete: {
              async click(row: EnocloudCommonDefinitions['AdvisorTeamDto']) {
                await this.table.delete({ paths: [row.id] })
                return this.table.get()
              }
            },
            deletes: {
              click(index: number) {
                this.detail.form.data.members.splice(index, 1)
              }
            },
            name: {
              async click(row: EnocloudCommonDefinitions['WorkingTeamDto']) {
                this.detail.form.init()
                this.detail.form.data.id = row.id
                await this.detail.form.get()
                this.detail.visible = true
              }
            }
          }
        }
      },
      detail: {
        visible: false,
        children: {
          form: {
            is: 'form',
            data: {
              name: '',
              branch: {},
              status: {},
              bonusType: {},
              bonusValue: 0,
              bonusValuePercent: 0,
              members: [],
              comment: ''
            },
            ajax: {
              get: {
                action: 'GET /enocloud/common/workingteam/:workingTeamId',
                data: 'object',
                loading: true,
                params(params) {
                  params.paths = [this.detail.form.data.id]
                }
              },
              submit: {
                action: 'POST /enocloud/common/workingteam',
                loading: true,
                validate: true,
                params(params) {
                  params.payload = this.detail.form.data
                }
              },
              update: {
                action: 'PUT /enocloud/common/workingteam',
                loading: true,
                validate: true,
                params(params) {
                  params.payload = this.detail.form.data
                }
              }
            },
            rules: {
              name: [{ required: true, message: '请输入班组名称' }]
            },

            children: {
              inspectionPlanItems: {
                data: [] as InspectionPlanItems[],
                add: {
                  click() {
                    this.detail.form.data.members.push({
                      user: {},
                      assignmentWeight: ''
                    })
                  }
                },
                deletes: {
                  click(index: number) {
                    this.detail.form.data.members.splice(index, 1)
                  }
                }
              }
            }
          },
          footer: {
            cancel: {
              click() {
                this.detail.visible = false
              }
            },
            confirm: {
              async click() {
                await this.detail.form[this.detail.form.data.id ? 'update' : 'submit']()
                return this.table.get().then(() => (this.detail.visible = false))
              }
            }
          }
        }
      }
    }
  },

  mounted() {
    this.table.get()
  }
})
</script>
